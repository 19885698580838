import { WorkOrderStatus } from '@/graphql/types';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { WorkOrderAllStatuses } from '@/modules/workOrders/types/workOrder';
import { Box, Flex, Icon, UseRadioProps, useRadio, useRadioGroup } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';

type RadioCardProps = {
  children: ReactNode;
} & UseRadioProps;

const RadioCard: FC<RadioCardProps> = (props: RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label' width='100%' mx='0'>
      <input {...input} />
      <Box
        color='primary.600'
        backgroundColor='neutral.0'
        borderColor=''
        {...checkbox}
        fontSize='small'
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        _checked={{
          bg: 'primary.600',
          color: 'neutral.0',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        py={2}
        px={{ base: 0, md: 4 }}
        textAlign='center'
      >
        {props.children}
      </Box>
    </Box>
  );
};

export type WorkOrderStatusRadioProps = {
  updateWorkOrderStatus: (nextValue: WorkOrderStatus) => void;
  status: WorkOrderStatus;
};

const WorkOrderStatusRadio: FC<WorkOrderStatusRadioProps> = (props: WorkOrderStatusRadioProps) => {
  const { updateWorkOrderStatus, status } = props;

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    value: status,
    onChange: updateWorkOrderStatus,
  });
  const { getStatusAppearanceInfo } = useWorkOrderStatus();

  useEffect(() => {
    setValue(status);
  }, [status, setValue]);

  const group = getRootProps();

  return (
    <Flex width='100%' gap='2' justifyContent='space-between' {...group}>
      {WorkOrderAllStatuses.map((status) => {
        const info = getStatusAppearanceInfo(status);
        const radio = getRadioProps({ value: info.value });
        return (
          <RadioCard key={info.value} {...radio}>
            <Icon
              verticalAlign={{ base: 'middle', md: 'text-bottom' }}
              as={info.icon}
              fontSize={{ base: 'xs', md: 'sm' }}
              mt={{ base: '-2px', md: 'unset' }}
              mr='1'
            />
            {info.label}
          </RadioCard>
        );
      })}
    </Flex>
  );
};

export default WorkOrderStatusRadio;
